'use client';
import { useMemo } from 'react';
import { type CircleLayer, Layer, type LineLayer } from 'react-map-gl';
import { USER_TILES_SOURCE_ID } from './user-tiles-source';

export const ROUTE_SELECT_LAYER_INTERACTIVE_ID = 'route-base';
interface JourneyLayersProps {
  journeyId: bigint | null;
}
export function JourneyLayers({ journeyId }: JourneyLayersProps) {
  const progressLayer = useMemo(
    () => journeyProgressLayerJson({ journeyId }),
    [journeyId],
  );

  const trackLayer = useMemo(
    () => journeyTrackLayerJson({ journeyId }),
    [journeyId],
  );

  return (
    <>
      <Layer {...trackLayer} beforeId="_road_overlays" />
      <Layer {...progressLayer} beforeId="_road_overlays" />
    </>
  );
}

function journeyProgressLayerJson(config: JourneyLayersProps): LineLayer {
  const { journeyId } = config;
  return {
    id: 'journey-progress',
    source: USER_TILES_SOURCE_ID,
    'source-layer': 'journey',
    type: 'line',
    paint: {
      'line-width': 2,
      'line-color': [
        //TODO -- this only works for run, not cycle
        'case',
        ['==', ['get', 'complete'], 1],
        `hsl(200, 100%, 50%)`, // color for complete
        ['==', ['get', 'complete'], 3],
        `hsl(200, 100%, 50%)`, // color for complete
        `hsl(0, 70%, 40%)`, // color for incomplete
      ],
    },
    minzoom: 2,
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    filter: ['==', 'journeyID', Number(journeyId)],
  };
}

function journeyTrackLayerJson(config: JourneyLayersProps): CircleLayer {
  const { journeyId } = config;
  return {
    id: 'journey-track',
    source: USER_TILES_SOURCE_ID,
    'source-layer': 'journey-track',
    type: 'circle',
    paint: {
      'circle-radius': 4,
      'circle-color': 'hsl(200, 30%, 40%)',
    },
    filter: ['==', ['get', `j${journeyId}`], 1],
  };
}
